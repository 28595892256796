<script setup lang="ts"></script>
<template>
  <div class="flex h-dvh flex-col items-center justify-center">
    <UCard class="m-10">
      <span class="text-8xl">404</span>
    </UCard>
    <UButton class="shadow-lg">
      <NuxtLink :to="{ name: 'index' }"> back to home </NuxtLink>
    </UButton>
  </div>
</template>
