<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'overscroll-y-none',
  },
})
const colorStore = useColorStore()
const appConfig = useAppConfig()
//set nuxt ui color
appConfig.ui.primary = colorStore.value || appConfig.ui.primary
//set element-plus primary color
const el = ref(null)
const color = useCssVar('--el-color-primary', el)
color.value = 'rgb(var(--color-primary-500) / 1)'
// const isApple = () => { //not use in ssr
//   return /Macintosh|iPhone|iPad|iPod/i.test(navigator.userAgent)
// }
const { isApple, isIos, isMacOS } = useDevice()
const apple = isApple || isIos || isMacOS
const notAppleName = useNotAppleTransitionName()
const isAppleName = useAppleTransitionName()
const fps = useFps()
const lowperf = useLowPerf()
const lowperftimeout = ref(
  setTimeout(() => {
    lowperf.value = false
  }, 4),
)
watch(fps, (v, ov) => {
  if (v < 8 && ov < 9 && ov > v) {
    lowperf.value = true
    clearTimeout(lowperftimeout.value)
    lowperftimeout.value = setTimeout(() => {
      lowperf.value = false
    }, 4000000)
  }
})
</script>
<template>
  <NuxtPwaManifest />
  <div>
    <NuxtLoadingIndicator />
    <UNotifications />
    
    <NuxtLayout>
      <NuxtPage
        :transition="
          lowperf
            ? false
            : {
                // isApple()
                name: apple ? isAppleName : notAppleName,
                // mode: 'out-in'
                // mode: 'in-out',
              }
        "
      />
    </NuxtLayout>
  </div>
</template>
<style>
/* Transitions */
.ios-leave-active {
  animation: leaveToLeft 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply pointer-events-none absolute left-0 top-0 z-0 max-h-dvh w-dvw touch-none select-none overflow-hidden;
}

.ios-enter-active {
  animation: enterFromLeft 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply z-0 max-h-dvh overflow-hidden;
}

.l2-leave-active {
  animation: leaveToLeft 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply pointer-events-none absolute left-0 top-0 z-0 max-h-dvh w-dvw touch-none select-none overflow-hidden;
}

.l2-enter-active {
  animation: enterFromBack 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply z-0 max-h-dvh overflow-hidden;
}

.todeep-leave-active {
  animation: leaveToFront 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply pointer-events-none absolute left-0 top-0 z-10 max-h-dvh w-dvw touch-none select-none overflow-hidden;
}
.todeep-enter-active {
  animation: enterFromBack 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply z-0 max-h-dvh overflow-hidden;
}

.toshallow-leave-active {
  animation: leaveToBack 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply pointer-events-none absolute left-0 top-0 z-0 max-h-dvh w-dvw touch-none select-none overflow-hidden;
}

.toshallow-enter-active {
  animation: enterFromFront 1000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  @apply pointer-events-none z-10 max-h-dvh w-dvw touch-none select-none overflow-hidden;
}

@keyframes leaveToFront {
  from {
    transform: perspective(100px) translate3d(0, 0, 0);
    @apply opacity-100 brightness-100;
  }
  to {
    transform: perspective(100px) translate3d(0, 0, 90px);
    @apply opacity-0 brightness-125;
  }
}
@keyframes enterFromFront {
  from {
    transform: perspective(100px) translate3d(0, 0, 90px);
    @apply opacity-0 brightness-125;
  }
  to {
    transform: perspective(100px) translate3d(0, 0, 0);
    @apply opacity-100 brightness-100;
  }
}

@keyframes leaveToBack {
  from {
    transform: perspective(100px) translate3d(0, 0, 0);
    @apply opacity-100 brightness-100;
  }
  to {
    transform: perspective(100px) translate3d(0, 0, -250px);
    @apply opacity-10 brightness-90;
  }
}
@keyframes enterFromBack {
  from {
    transform: perspective(100px) translate3d(0, 0, -250px);
    @apply opacity-10 brightness-90;
  }
  to {
    transform: perspective(100px) translate3d(0, 0, 0);
    @apply opacity-100 brightness-100;
  }
}

@keyframes leaveToLeft {
  from {
    transform: rotate3d(0, 1, 0, 0deg) perspective(100px)
      translate3d(0, 0dvh, 0px);
    @apply brightness-100;
  }
  to {
    transform: rotate3d(0, 1, 0, 70deg) perspective(100px)
      translate3d(-200vw, 10dvh, -250px);
    @apply brightness-90;
  }
}

@keyframes enterFromLeft {
  from {
    transform: rotate3d(0, 1, 0, 70deg) perspective(100px)
      translate3d(-200vw, 10dvh, -250px);
    @apply brightness-90;
  }
  to {
    transform: rotate3d(0, 1, 0, 0deg) perspective(100px)
      translate3d(0dvw, 0dvh, 0px);
    @apply brightness-100;
  }
}
</style>
