export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return
  const nuxtApp = useNuxtApp()
  if (
    import.meta.client &&
    nuxtApp.isHydrating &&
    nuxtApp.payload.serverRendered
  )
    return

  const notAppleName = useNotAppleTransitionName()
  const isAppleName = useAppleTransitionName()
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length

  notAppleName.value = toDepth > fromDepth ? 'todeep' : 'toshallow'

  if (toDepth === 2 && fromDepth === 2) {
    isAppleName.value = 'ios'
  }
  if (toDepth == 3 && fromDepth == 2) {
    isAppleName.value = 'l2'
  }
  if (toDepth > 2 && fromDepth > 2) {
    isAppleName.value = notAppleName.value
  }
  return
})
