
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "fr",
  "ja",
  "zh-Hans"
]

export const localeLoaders = {
  "en": [{ key: "../lang/home-en-US.json", load: () => import("../lang/home-en-US.json" /* webpackChunkName: "locale__opt_buildhome_repo_lang_home_en_US_json" */), cache: true },
{ key: "../lang/menu-en-US.json", load: () => import("../lang/menu-en-US.json" /* webpackChunkName: "locale__opt_buildhome_repo_lang_menu_en_US_json" */), cache: true },
{ key: "../lang/detail-en-US.json", load: () => import("../lang/detail-en-US.json" /* webpackChunkName: "locale__opt_buildhome_repo_lang_detail_en_US_json" */), cache: true },
{ key: "../lang/CPtracker-en-US.json", load: () => import("../lang/CPtracker-en-US.json" /* webpackChunkName: "locale__opt_buildhome_repo_lang_CPtracker_en_US_json" */), cache: true }],
  "es": [{ key: "../lang/es-ES.ts", load: () => import("../lang/es-ES.ts?hash=70286398&locale=es" /* webpackChunkName: "locale__opt_buildhome_repo_lang_es_ES_ts" */), cache: false }],
  "fr": [{ key: "../lang/fr-FR.ts", load: () => import("../lang/fr-FR.ts?hash=0947d9ed&locale=fr" /* webpackChunkName: "locale__opt_buildhome_repo_lang_fr_FR_ts" */), cache: false }],
  "ja": [{ key: "../lang/home-ja.ts", load: () => import("../lang/home-ja.ts?hash=f6d3f26a&locale=ja" /* webpackChunkName: "locale__opt_buildhome_repo_lang_home_ja_ts" */), cache: false },
{ key: "../lang/CPtracker-ja.ts", load: () => import("../lang/CPtracker-ja.ts?hash=b191eb0c&locale=ja" /* webpackChunkName: "locale__opt_buildhome_repo_lang_CPtracker_ja_ts" */), cache: false }],
  "zh-Hans": [{ key: "../lang/home-zh-Hans.ts", load: () => import("../lang/home-zh-Hans.ts?hash=96bf5f5a&locale=zh-Hans" /* webpackChunkName: "locale__opt_buildhome_repo_lang_home_zh_Hans_ts" */), cache: false },
{ key: "../lang/menu-zh-Hans.ts", load: () => import("../lang/menu-zh-Hans.ts?hash=8f8603a5&locale=zh-Hans" /* webpackChunkName: "locale__opt_buildhome_repo_lang_menu_zh_Hans_ts" */), cache: false },
{ key: "../lang/detail-zh-Hans.ts", load: () => import("../lang/detail-zh-Hans.ts?hash=7f6180ec&locale=zh-Hans" /* webpackChunkName: "locale__opt_buildhome_repo_lang_detail_zh_Hans_ts" */), cache: false },
{ key: "../lang/CPtracker-zh-Hans.ts", load: () => import("../lang/CPtracker-zh-Hans.ts?hash=f8a6bf50&locale=zh-Hans" /* webpackChunkName: "locale__opt_buildhome_repo_lang_CPtracker_zh_Hans_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/opt/buildhome/repo/lang/home-en-US.json",
        "/opt/buildhome/repo/lang/menu-en-US.json",
        "/opt/buildhome/repo/lang/detail-en-US.json",
        "/opt/buildhome/repo/lang/CPtracker-en-US.json"
      ]
    },
    {
      "code": "es",
      "name": "Español (Spain)",
      "files": [
        "/opt/buildhome/repo/lang/es-ES.ts"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "files": [
        "/opt/buildhome/repo/lang/fr-FR.ts"
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "files": [
        "/opt/buildhome/repo/lang/home-ja.ts",
        "/opt/buildhome/repo/lang/CPtracker-ja.ts"
      ]
    },
    {
      "code": "zh-Hans",
      "name": "中文",
      "files": [
        "/opt/buildhome/repo/lang/home-zh-Hans.ts",
        "/opt/buildhome/repo/lang/menu-zh-Hans.ts",
        "/opt/buildhome/repo/lang/detail-zh-Hans.ts",
        "/opt/buildhome/repo/lang/CPtracker-zh-Hans.ts"
      ]
    }
  ],
  "defaultLocale": "zh-Hans",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://dv.orcinusorca.org/",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/home-en-US.json"
      },
      {
        "path": "/opt/buildhome/repo/lang/menu-en-US.json"
      },
      {
        "path": "/opt/buildhome/repo/lang/detail-en-US.json"
      },
      {
        "path": "/opt/buildhome/repo/lang/CPtracker-en-US.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español (Spain)",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/es-ES.ts",
        "cache": false
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/fr-FR.ts"
      }
    ]
  },
  {
    "code": "ja",
    "name": "日本語",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/home-ja.ts"
      },
      {
        "path": "/opt/buildhome/repo/lang/CPtracker-ja.ts"
      }
    ]
  },
  {
    "code": "zh-Hans",
    "name": "中文",
    "files": [
      {
        "path": "/opt/buildhome/repo/lang/home-zh-Hans.ts"
      },
      {
        "path": "/opt/buildhome/repo/lang/menu-zh-Hans.ts"
      },
      {
        "path": "/opt/buildhome/repo/lang/detail-zh-Hans.ts"
      },
      {
        "path": "/opt/buildhome/repo/lang/CPtracker-zh-Hans.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
