export default defineAppConfig({
  title: 'HDDolby',
  ui: {
    primary: 'spindle',
    gray: 'neutral',
    notifications: {
      // Show toasts at the top right of the screen
      position: ' top-0 end-0 bottom-auto ',
    },
    notification: {
      transition: {
        enterActiveClass: 'transform ease-out duration-500 transition-all',
        enterFromClass:
          'translate-y-2 opacity-0 sm:translate-x-0 sm:-translate-y-2 scale-50 ',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-y-0 scale-100 ',
        leaveActiveClass: 'transition ease-in duration-100',
        leaveFromClass: 'opacity-100 scale-100 ',
        leaveToClass: 'opacity-0 scale-50 ',
      },
    },
  },
})
