export const useisLogin = () =>
  // share across tabs
  useCookie<boolean>('isLogin', {
    default: () => false,
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useErrorMessage = () => useState('errorMessage', () => '') //传递报错消息
export const useErrorDetails = () => useState('errorDetails', () => null) //传递报错消息
export const useBGPlay = () => useState<boolean>('bgplay', () => true)
export const useUserInfoInterval = () => useState('userInfoInterval', () => 0) //TODO:useIntervalFn
export const useLowPerf = () => useState<boolean>('lowperf', () => false)
export const useColorStore = () =>
  useCookie('user-color-style', {
    default: () => 'spindle',
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useShowHOTStore = () =>
  useCookie('user-show-HOT', {
    default: () => true,
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useShowDescriptionStore = () =>
  useCookie('user-show-Description', {
    default: () => true,
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useShowNFOStore = () =>
  useCookie('user-show-NFO', {
    default: () => true,
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useShowTMDbStore = () =>
  useCookie('user-show-TMDb', {
    default: () => true,
    maxAge: 60000000,
    sameSite: 'lax',
  })
export const useAppleTransitionName = () =>
  useState<string>('isAppleTransitionName', () => 'ios')
export const useNotAppleTransitionName = () =>
  useState<string>('notAppleTransitionName', () => 'todeep')
