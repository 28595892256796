import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45transition_45global from "/opt/buildhome/repo/middleware/pageTransition.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45transition_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  loginpage: () => import("/opt/buildhome/repo/middleware/loginpage.ts"),
  torrentlist: () => import("/opt/buildhome/repo/middleware/torrentlist.ts")
}