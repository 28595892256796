export default defineNuxtPlugin({
  enforce: 'pre', // clients will be ready to use by other plugins, Pinia stores etc.
  setup() {
    const clients = useRuntimeConfig().public.openFetch
    const localFetch = useRequestFetch()
    const toast = useToast()
    const isLogin = useisLogin()
    const errorMessage = useErrorMessage() //传递报错消息
    const errorDetails = useErrorDetails() //传递报错消息
    // const XSRF = useCookie('XSRF-TOKEN')
    return {
      provide: Object.entries(clients).reduce(
        (acc, [name, options]) => ({
          ...acc,
          //   [name]: createOpenFetch(options, localFetch)

          // or add the logging:

          [name]: createOpenFetch(
            (localOptions) => ({
              // headers:{
              //   'X-CSRF-TOKEN': '',
              //       'X-XSRF-TOKEN': XSRF.value || '',
              // },
              retry: 2,
              retryDelay: 200,
              credentials: 'include',
              onRequest({ options }) {
                options.headers = options.headers || {}
                const XSRF = useCookie('XSRF-TOKEN')
                options.headers = {
                  // 'X-CSRF-TOKEN': '',
                  'X-XSRF-TOKEN': XSRF.value || 'xs',
                  ...options.headers,
                }
              },
              onResponseError({ response }) {
                switch (response.status) {
                  case 401:
                  case 403:
                  case 419:
                    isLogin.value = false
                  // break
                  // eslint-disable-next-line no-fallthrough
                  case 400:
                    toast.add({
                      icon: 'i-heroicons-exclamation-circle-16-solid',
                      title: response._data.error.message,
                      description: response._data.error.details,
                    })
                    errorMessage.value = response._data.error.message
                    errorDetails.value = response._data.error.details
                    setTimeout(() => {
                      errorMessage.value = ''
                    }, 1200)
                    break
                  case 500:
                    toast.add({
                      icon: 'i-heroicons-exclamation-circle-16-solid',
                      title: 'Error',
                      description: '500 error',
                      click: () => {
                        navigateTo({ name: 'index' })
                      },
                      // callback: () => {
                      //   navigateTo({ name: 'index' })
                      // },
                    })
                    break
                  default:
                    break
                }
              },
              ...options,
              ...localOptions,
            }),
            localFetch,
          ),
        }),
        {},
      ),
    }
  },
})
